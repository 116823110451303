.google-places-autocomplete {
  width: 100%;
  position: relative;
}

.google-places-autocomplete__input {
  width: calc(100% - 20px);
  padding: 10px;
  border: none;
  margin-bottom: 2px;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.09);
}

.google-places-autocomplete__input:active,
.google-places-autocomplete__input:focus,
.google-places-autocomplete__input:hover {
  outline: none;
  border: none;
}

.google-places-autocomplete__suggestions-container {
  background: white;
  border-radius: 0 0 5px 5px;
  color: black;
  position: absolute;
  width: 100%;
  z-index: 9;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.09);
}

.google-places-autocomplete__suggestion {
  font-size: 1rem;
  text-align: left;
  padding: 10px;
  text-transform: uppercase;
}

.google-places-autocomplete__suggestion:hover {
  font-size: 1rem;
  text-align: left;
  padding: 10px;
  background-color: #cae0d5;
  text-transform: uppercase;
  /* cursor: pointer; */
  /* border-radius: 4px 4px 0 0; */
  /* box-sizing: border-box;
  color: rgba(0,0,0,.65);
  display: block;
  font-weight: 400;
  line-height: 22px;
  overflow: hidden;
  padding: 5px 12px;
  position: relative;
  text-overflow: ellipsis;
  transition: background .3s;
  user-select: none;
  white-space: nowrap; */
}
